import React from "react"
import {
    ComplianceAsCodeSection, CTASection,
    OverviewHeroSection,
    IdentityAwareSection,
    PreventativePolicySection,
    SupportCloudSection,
    WhitepaperSection,
    CoverageSection,
    ToolsCarouselSection,
} from "@/layout"

const IndexPage: React.FC = () => {
    return (
        <div className={'container-overview'}>
          <OverviewHeroSection />
          <WhitepaperSection />
          <PreventativePolicySection />
          <CoverageSection />
          <SupportCloudSection />
          <IdentityAwareSection />
          <ComplianceAsCodeSection />
          <ToolsCarouselSection/>
          <CTASection />
        </div>
    )
}

export default IndexPage
